import './WeaveLoader.css';

import React from 'react';

export interface WeaveLoaderProps {
  className?: string;
  size?: string;
}

export const WeaveLoader: React.FC<WeaveLoaderProps> = React.memo(
  ({className, size = 'huge'}) => {
    return (
      <div className={`weave-loader ${size} ${className}`}>
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.5627 4.06824C11.1508 1.71538 9.12077 0 6.74832 0H4.88863C2.18872 0 0 2.20413 0 4.92306V16.5022C0 19.5631 2.74425 21.882 5.73755 21.3505L9.50731 20.6811C12.1662 20.2089 13.9416 17.6556 13.4728 14.978L11.5627 4.06824Z"
            fill="#EDEFF2"
          />
          <path
            d="M21.0603 28.8275C20.5914 26.1499 22.3668 23.5965 25.0257 23.1244L35.4246 21.2779C38.0835 20.8057 40.6191 22.5936 41.0879 25.2713L42.9215 35.7434C43.3903 38.421 41.6149 40.9744 38.956 41.4465L28.5571 43.293C25.8982 43.7652 23.3627 41.9773 22.8938 39.2996L21.0603 28.8275Z"
            fill="#EDEFF2"
          />
          <path
            d="M4.03967 26.8503C1.70332 27.2652 0 29.3095 0 31.6986V42.4961C0 45.557 2.74424 47.8759 5.73754 47.3444L13.9212 45.8912C16.58 45.4191 18.3554 42.8657 17.8866 40.1881L16.053 29.716C15.5842 27.0384 13.0486 25.2505 10.3897 25.7227L4.03967 26.8503Z"
            fill="#EDEFF2"
          />
          <path
            d="M46.8574 58.2219C47.3851 61.2362 45.0824 63.9998 42.043 63.9998H31.3209C28.9485 63.9998 26.9185 62.2844 26.5065 59.9316L25.4747 54.0387C25.0059 51.3611 26.7813 48.8077 29.4402 48.3356L39.8391 46.4891C42.498 46.0169 45.0335 47.8049 45.5023 50.4825L46.8574 58.2219Z"
            fill="#EDEFF2"
          />
          <path
            d="M21.0442 58.2221C21.572 61.2365 19.2693 64 16.2298 64H4.88863C2.18872 64 0 61.7959 0 59.0769V57.6947C0 55.3056 1.70334 53.2613 4.03971 52.8464L14.8042 50.935C17.4632 50.4628 19.9987 52.2507 20.4675 54.9284L21.0442 58.2221Z"
            fill="#EDEFF2"
          />
          <path
            d="M16.8996 5.77903C16.3719 2.76468 18.6745 0.00120192 21.714 0.00120192H32.4362C34.8086 0.00120192 36.8385 1.71649 37.2506 4.06927L38.3827 10.534C38.8516 13.2117 37.0761 15.7651 34.4172 16.2373L24.0179 18.0838C21.3589 18.5559 18.8234 16.7679 18.3546 14.0903L16.8996 5.77903Z"
            fill="#EDEFF2"
          />
          <path
            d="M64 47.7143C64 44.6534 61.2557 42.3345 58.2624 42.8661L54.9227 43.4591C52.2639 43.9313 50.4885 46.4846 50.9573 49.1622L52.7675 59.5008C53.1794 61.8536 55.2094 63.569 57.5818 63.569H59.1114C61.8113 63.569 64 61.3649 64 58.6459V47.7143Z"
            fill="#EDEFF2"
          />
          <path
            d="M57.7883 17.02C60.7816 16.4884 63.5259 18.8073 63.5259 21.8682V32.6658C63.5259 35.0549 61.8226 37.0992 59.4862 37.5141L53.5657 38.5655C50.9067 39.0376 48.3712 37.2497 47.9023 34.5721L46.0687 24.1C45.5999 21.4224 47.3753 18.869 50.0342 18.3969L57.7883 17.02Z"
            fill="#EDEFF2"
          />
          <path
            d="M47.652 0C44.6126 0 42.3099 2.76349 42.8376 5.77784L43.5144 9.64341C43.9832 12.3211 46.5188 14.1091 49.1777 13.6369L59.5127 11.8017C61.8491 11.3868 63.5524 9.34253 63.5524 6.95341V4.92306C63.5524 2.20413 61.3637 0 58.6638 0H47.652Z"
            fill="#EDEFF2"
          />
        </svg>
      </div>
    );
  }
);
